import { Col, CardTitle } from "reactstrap";

const TitleCard = () => {

  return (
    <Col sm="12" lg="12" style={{ marginBottom: '10px', textAlign: 'center'}} className="title-card">
      <CardTitle className="mobile-CardTitle" style={{ textTransform: "capitalize", fontWeight: '800' }} tag="h5">
        ASHTANGA HRIDAYAM KALPASIDDHI STHANAM
      </CardTitle>
      <CardTitle className="mobile-CardTitle" style={{ textTransform: "capitalize", fontWeight: '800' }} tag="h5">
        (अष्टाङ्गहृदयम् कल्पसिद्धिस्थानम्)
      </CardTitle>
      <CardTitle className="mobile-CardTitle" style={{ textTransform: "capitalize", fontWeight: '800' }} tag="h5">
        Learning sloka made simple
      </CardTitle>
    </Col>
  );
};

export default TitleCard;
