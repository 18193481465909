import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import Chart from "react-apexcharts";

const VoiceSearchIcon = (props) => {
  const classes = props.classes;
  return (
    <div className="mic-div">
      <button type="button" className={classes} onClick={()=>props.clk()}>
        <i className="bi bi-mic-fill"></i>
      </button>
    </div>
  );
};

export default VoiceSearchIcon;
