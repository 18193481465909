
import { React, useEffect, useState } from "react";
import { Col, Row, FormGroup, Label, Input, Table } from "reactstrap";
import SalesChart from "../components/dashboard/SalesChart";
import Feeds from "../components/dashboard/Feeds";
import ProjectTables from "../components/dashboard/ProjectTable";
import TopCards from "../components/dashboard/TopCards";
import Blog from "../components/dashboard/Blog";
import bg1 from "../assets/images/bg/bg1.jpg";
import bg2 from "../assets/images/bg/bg2.jpg";
import bg3 from "../assets/images/bg/bg3.jpg";
import bg4 from "../assets/images/bg/bg4.jpg";
import json from "../services/jsonData.json";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Button,
} from "reactstrap";
import TitleCard from "./TitleCard";
const BlogData = [
  {
    image: bg1,
    title: "This is simple blog",
    subtitle: "2 comments, 1 Like",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    btnbg: "primary",
  },
  // {
  //   image: bg2,
  //   title: "Lets be simple blog",
  //   subtitle: "2 comments, 1 Like",
  //   description:
  //     "This is a wider card with supporting text below as a natural lead-in to additional content.",
  //   btnbg: "primary",
  // },
  // {
  //   image: bg3,
  //   title: "Don't Lamp blog",
  //   subtitle: "2 comments, 1 Like",
  //   description:
  //     "This is a wider card with supporting text below as a natural lead-in to additional content.",
  //   btnbg: "primary",
  // },
  // {
  //   image: bg4,
  //   title: "Simple is beautiful",
  //   subtitle: "2 comments, 1 Like",
  //   description:
  //     "This is a wider card with supporting text below as a natural lead-in to additional content.",
  //   btnbg: "primary",
  // },
];

const Favorites = () => {
  let navigate = useNavigate();
  const [jsonData, setJsonData] = useState({ chapters: [] })
  const [favorites, setFavorites] = useState([]);

  // const [TempJsonData, setTempJsonData] = useState(json) 

  useEffect(() => {
    setJsonData(json)
    console.log(json)
  }, [])

  useEffect(() => {
    let favorited = localStorage.getItem('fav');
    if (favorited) {
      let parsefavorited = JSON.parse(favorited)
      setFavorites(parsefavorited)
    }

    // setFavorite( favorited === '1' ? 1 : 0)
  });


  const getStringBetweenParenthese = (inputString) => {
    const regex = /\((.*?)\)/;
    const match = regex.exec(inputString);
    return match ? match[1] : null;
    // return inputString;
  }
  return (
    <div className="gallery">
      <Row>
        <i onClick={() => navigate("/starter")} class="bi bi-arrow-left-short back-button"></i>
        <TitleCard />
      </Row>

      <Row>
{/* {JSON.stringify(favorites)} */}
        {/* <i onClick={() => navigate("/starter")} style={{ cursor:'pointer',fontSize:'30px', color:'white' , textAlign:'right'}}  class="bi bi-house"></i> */}
        {jsonData.chapters.map((chapter, i) => (
          favorites.includes(String(i)) &&
          <Col key={i} style={{ cursor: 'pointer', padding: '5px' }} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" onClick={() => navigate("/slog/" + i)}>
            <div class="card card-body" style={{ height: '100%', marginBottom: '0px' }}>
              <h5 style={{ textAlign: 'center' }}><i class="bi bi-book-half" style={{ color: '#a00395' }}></i> </h5>
              <h5 style={{ textAlign: 'center' }} class="card-title"> {(i + 1 + '.')}{getStringBetweenParenthese(chapter?.chapter_heading)}</h5>
              {/* <p class="card-text">{getStringBetweenParenthese(chapter?.chapter_heading)}</p> */}
              {/* <p class="card-text">{"Total Slokas- " + (chapter.slogs.length + 1)}</p> */}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Favorites;
